import { getItem, postItem, putItem } from "../../../api/api-methods";

export const fetchPostInventory = async (inventory, product) => {
  try {
    const URL = `/app-inventarios/inventario`;
    const responseData = await postItem(URL, {
      producto: product,
      opcion_producto: inventory.tipo_opcion,
      almacen: inventory.almacen,
      cantidad_disponible: inventory.cantidad_disponible,
      unidad_medida: inventory.unidad_medida,
      comentarios: inventory.comentarios,
      fecha_ultima_actualizacion: inventory.fecha_ultima_actualizacion,
      autor: inventory.autor,
      institucion_educativa: inventory.institucion_educativa
    });
    return responseData;
  } catch (error) {
    console.error('Error al intentar crear inventario:', error);
  }
};


export const fetchPutInventory = async (inventoryObj, product, ammount) => {
  try {
    const URL = `/app-inventarios/inventario/${inventoryObj.id}`;
    // console.log({responseData},{inventoryObj}, {product}, {ammount});
    const responseData = await putItem(URL, {
      id: inventoryObj.id,
      producto: product,
      opcion_producto: inventoryObj.opcion_producto,
      almacen: inventoryObj.almacen,
      cantidad_disponible: inventoryObj.cantidad_disponible + ammount,
      unidad_medida: inventoryObj.unidad_medida,
      comentarios: inventoryObj.comentarios,
      fecha_ultima_actualizacion: new Date(),
      autor: inventoryObj.autor,
      institucion_educativa: inventoryObj.institucion_educativa,
    });
    // console.log({responseData});
    return responseData;
  } catch (error) {
    console.error('Error al intentar editar inventario:', error);
  }
};


export const fetchInventoryByProduct = async (product, institution, user) => {
  try {
    const URL = `/app-inventarios/filters/inventario?producto=${product}&autor=${user}&institucion_educativa=${institution}&estatus_sistema=true`;
    const responseData = await getItem(URL);
    return responseData;
  } catch (error) {
    console.error('Error al obtener inventario por ID:', error);
  }
};
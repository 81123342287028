import { fetchPostImage } from "../../shared/helpers/imagesOptions";

export const CreateAndAssignImageToProduct = {
  methods: {
    async createImagesArray(images) {
      let successImages = [];
      try {
        const imagePromises = images.map(img => fetchPostImage(img, 1));
        const results = await Promise.all(imagePromises);
        successImages = results.map(result => result.id);
      } catch (error) {
        console.error('Error in createImagesArray:', error);
      }
      return successImages;
    }
  }
}
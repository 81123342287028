import { fetchPostDocument } from "../../shared/helpers/documentOptions";

export const CreateAndAssignDocToProduct = {
  methods: {
    async createDocumentsArray(documentList, userId, institutionId) {
      let successDocuments = [];
      try {
        const documentPromises = documentList.map(doc => fetchPostDocument(doc, 1, userId, institutionId));
        const results = await Promise.all(documentPromises);
        successDocuments = results.map(res => res.id);
      } catch (error) {
        console.error('Error in createDocumentsArray:', error);
      }
      return successDocuments;
    }
  }
}
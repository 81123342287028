import { postItem } from "../../../api/api-methods";

export const fetcPostPriceDistributor = async (price) => {
  try {
    const URL = `/app-productos/precio-distribuidor`;
    const responseData = await postItem(URL, price);
    return responseData;
  } catch (error) {
    console.error('Error al intentar crear precio distribuidor:', error);
  }
};

export const fetcPutPriceDistributor = async (expense) => {
  try {
    const URL = ``;
    const responseData = await postItem(URL, expense);
    return responseData;
  } catch (error) {
    console.error('Error al intentar crear egreso:', error);
  }
};

export const fetchAssignPriceDistributor = async (priceDistributor, product) => {
  const url = '/app-productos/helpers/add-precio-producto'
  try {
    const responseData = await postItem(url, {
      id_precio: priceDistributor,
      id_producto: product
    });
    return responseData;
  } catch (error) {
    console.error('Error al intentar crear egreso:', error);
  }
};
import { CreateAndAssignDocToProduct } from "./CreateAndAssignDocToProduct";
import { CreateAndAssignImageToProduct } from "./CreateAndAssignImageToProduct";

export const ProcessFileUpload = {
  mixins: [
    CreateAndAssignDocToProduct,
    CreateAndAssignImageToProduct,
  ],
  methods: {
    async processFileUpload(imagesObj, docsObj, userData, institutionId, imagesSelected, docsSelected) {
      try {
        let images = [];
        let documents = [];

        if (imagesObj && imagesObj.file.length) {
          images = await this.createImagesArray(imagesObj.file);
        }

        if (docsObj && docsObj.file.length) {
          documents = await this.createDocumentsArray(docsObj.file, userData.id, institutionId);
        }
        images = images.concat(imagesSelected);
        documents = documents.concat(docsSelected);

        return { images, documents };
      } catch (error) {
        console.error("An error occurred during file upload:", error);
        throw error;
      }
    }
  }
}
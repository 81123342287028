import { mapState } from 'vuex';

import { apiMixin } from '../../../shared/mixins/apiMixin';
import { BASE_INVENTORY_MOVEMENTS_PRODUCTS } from '../../helpers/statesProducts';
import { ProcessFileUpload } from '../../mixins/ProcessFileUploadProducts';
import { fileUtilsMixin } from '../../../shared/mixins/fileUtilsMixin';
import { getItem, postItem, putItem } from '../../../../api/api-methods';
import { saleUtilsMixing } from '../../../pos/mixins/saleUtilsMixin';
import { validationFormMixin } from '../../../../modules/shared/mixins/validationFormMixin';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import {
  fetcPostPriceDistributor,
  fetchAssignPriceDistributor,
} from '../../helpers/distributorPricingUtils';
import {
  fetchInventoryByProduct,
  fetchPostInventory,
  fetchPutInventory,
} from '../../helpers/inventoryUtils';
import { fetchPostProduct, fetchPutProduct } from '../../helpers/productUtils';

import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnCheckBox from '../../../shared/components/KnCheckBox.vue';
import KnDocItem from '../../../shared/components/KnDocItem.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormNoteOfMandatory from '../../../shared/components/KnFormNoteOfMandatory.vue';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnSelect from '../../../shared/components/KnSelect.vue';
import KnTabs from '../../../shared/components/KnTabs.vue';
import KnTextArea from '../../../shared/components/KnTextArea.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import KnDocsGalleryContainer from '../../../shared/components/KnDocsGalleryContainer/KnDocsGalleryContainer.vue';
import KnImagesGalleryContainer from '../../../shared/components/KnImagesGalleryContainer/KnImagesGalleryContainer.vue';

export default {
  components: {
    KnBackToTopButton,
    KnCheckBox,
    KnDocItem,
    KnDocsGalleryContainer,
    KnFormActionButtons,
    KnFormNoteOfMandatory,
    KnFormSubtitle,
    KnFormTitle,
    KnImagesGalleryContainer,
    KnLocalAlert,
    KnSelect,
    KnTabs,
    KnTextArea,
    KnTextField,
  },
  mixins: [
    apiMixin,
    fileUtilsMixin,
    paginationMixin,
    ProcessFileUpload,
    saleUtilsMixing,
    validationFormMixin,
  ],
  props: {
    entity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tabs: [
        { name: 'Información básica', value: 33 },
        { name: 'Información complementaria', value: 66 },
        { name: 'Inventario', value: 100 },
      ],
      valueDeterminate: 33,
      routerName: 'Productos',
      valid: true,
      productId: null,
      product: {
        prioridad_display: 0,
        calificacion: 0,
        destacado: false,
        cuarentena: false,
        peso_volumetrico: 1,
        moneda: {
          id: undefined,
        },
      },
      inventory: {
        opcion_producto: null,
        almacen: null,
        cantidad_disponible: null,
        unidad_medida: null,
        comentarios: null,
        fecha_ultima_actualizacion: new Date(),
        autor: null,
        institucion_educativa: null,
      },
      movement: {},
      brands: [],
      satCodes: [],
      measurementUnits: [],
      categories: [],
      subcategories: [],
      backorders: [],
      coins: [],
      taxes: [],
      options: [],
      warehouseArray: [],
      images: [],
      docs: [],
      movementTypes: [],
      movementReasons: [],
      distributionPrice: null,
      distributionPriceObj: null,
      purchasePrice: null,
      amountToAdd: null,
      taxesSelected: [],
      bkTaxesSelected: [],
      optionsSelected: [],
      bkOptionsSelected: [],
      imagesSelected: [],
      bkImagesSelected: [],
      docsSelected: [],
      bkDocsSelected: [],

      imagesObj: null,
      docsObj: null,
      photoDoc: null,

      // Pagination documents
      loadingDocuments: false,
      currentPageDocuments: 1,
      totalPagesDocuments: 1,

      // Pagination images
      loadingImages: false,
      currentPageImages: 1,
      totalPagesImages: 1,
      checkInventory: false,

      // Pagination satcodes
      loadingCodesSat: false,

      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /************************ */
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'warehouse']),
    title() {
      return this.entity === null ? 'Agregar producto' : 'Editar producto';
    },
    isNewMode() {
      return this.entity === null;
    },
    successAlertText() {
      return this.isNewMode
        ? 'Producto registrado con exito!'
        : 'Producto actualizado con exito!';
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info';
    },
    /*0inventoryIsValid() {
      return !this.objectHasNulls(this.inventory)
    },*/
    hasInventory() {
      return !this.isNewMode && this.entity.inventario !== null;
    },
    amountToAddValid() {
      return this.amountToAdd !== null && this.amountToAdd > 0;
    },
  },
  async created() {
    try {
      this.loading = true;
      this.alertText = 'Por favor, espere. Cargando...';
      this.showAlert = true;
      this.alertType = 'info';

      const fetchItems = async (url) => {
        const response = await getItem(url);
        return response.results;
      };

      const fetchPromises = [
        fetchItems(
          `/app-productos/filters/marca-producto?institucion_educativa=${this.institutionId}&estatus_sistema=true`
        ),
        fetchItems(
          `/app-productos/filters/uom?institucion_educativa=${this.institutionId}&estatus_sistema=true`
        ),
        fetchItems(
          `/app-productos/filters/categoria-producto?institucion_educativa=${this.institutionId}&estatus_sistema=true`
        ),
        fetchItems(
          `/app-productos/filters/sub-categoria-producto?institucion_educativa=${this.institutionId}&estatus_sistema=true`
        ),
        fetchItems(
          `/app-productos/filters/backorder?institucion_educativa=${this.institutionId}&estatus_sistema=true`
        ),
        fetchItems(
          `/app-administracion/filters/moneda?institucion_educativa=${this.institutionId}`
        ),
        fetchItems(
          `/app-productos/filters/impuesto?institucion_educativa=${this.institutionId}`
        ),
        fetchItems(
          `/app-productos/filters/opciones-producto?institucion_educativa=${this.institutionId}&estatus_sistema=true`
        ),
        fetchItems(
          `/app-inventarios/filters/almacen?institucion_educativa=${this.institutionId}&estatus_sistema=true`
        ),
      ];

      await this.loadSatCodes();
      await this.loadImages();
      await this.loadDocuments();

      const [
        brands,
        measurementUnits,
        categories,
        subcategories,
        backorders,
        coins,
        taxes,
        options,
        warehouseArray,
      ] = await Promise.all(fetchPromises);

      this.brands = brands;
      this.measurementUnits = measurementUnits;
      this.categories = categories;
      this.subcategories = subcategories;
      this.backorders = backorders;
      this.coins = coins;
      this.taxes = taxes;
      this.options = options;
      this.warehouseArray = warehouseArray;

      if (!this.isNewMode) {
        // console.log('Se deben llenar los campos');
        this.fillData();
      } else {
        this.product.institucion_educativa = this.institutionId;
        this.product.autor = this.userData.id;

        this.inventory.institucion_educativa = this.institutionId;
        this.inventory.autor = this.userData.id;
        this.inventory.almacen = this.warehouse.id;
      }

      this.loading = false;
      this.showAlert = false;
    } catch (error) {
      console.error('Error fetching data:', error);
      this.loading = false;
      this.showAlert = true;
      this.alertText =
        'Ocurrió un error al cargar los datos. Por favor, inténtelo de nuevo más tarde.';
      this.alertType = 'error';
    }
  },

  methods: {
    async loadSatCodes() {
      this.loadingCodesSat = true;
      try {
        const { limit, offset } = this.pagination;
        const limitFilter = limit !== null ? `&limit=${limit}` : '';
        const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';

        const response = await getItem(
          `/app-productos/filters/codigos-sat?institucion_educativa=${this.institutionId}${limitFilter}${offsetFilter}`
        );
        if (response && response.results) {
          this.satCodes = [...this.satCodes, ...response.results];
          this.setPaginationCount(response.count);
        }
      } catch (error) {
        console.error('Error loading SAT codes:', error);
      } finally {
        this.loadingCodesSat = false;
      }
    },

    async loadImages(page, limit = 5) {
      try {
        this.loadingImages = true;
        const currentOffset = page > 1 ? (page - 1) * limit : 0;

        const limitFilter = limit !== null ? `&limit=${limit}` : '';

        const offsetFilter =
          currentOffset !== 0 ? `&offset=${currentOffset}` : '';
        const imagesRes = await getItem(
          `/app-personas/filters/imagen?estatus_sistema=true${limitFilter}${offsetFilter}`
        );

        this.images = imagesRes.results;
        this.totalPagesImages = imagesRes.count;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingImages = false;
      }
    },
    async loadDocuments(page, limit = 5) {
      try {
        this.loadingDocuments = true;
        const currentOffset = page > 1 ? (page - 1) * limit : 0;

        const limitFilter = limit !== null ? `&limit=${limit}` : '';
        const offsetFilter =
          currentOffset !== 0 ? `&offset=${currentOffset}` : '';

        const documentsRes = await getItem(
          `/app-personas/filters/documento?estatus_sistema=true${limitFilter}${offsetFilter}`
        );
        this.docs = documentsRes.results;
        this.totalPagesDocuments = documentsRes.count;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingDocuments = false;
      }
    },

    async endIntersect() {
      if (this.satCodes.length > this.pagination.count) return;
      this.setPaginationPage(this.pagination.nextPage);
      await new Promise((resolve) => setTimeout(resolve, 500));
      await this.loadSatCodes();
    },

    async onScrollDocuments(value) {
      this.currentPageDocuments = value;
      await this.loadDocuments(this.currentPageDocuments);
    },

    async onScrollImages(value) {
      this.currentPageImages = value;
      await this.loadImages(this.currentPageImages);
    },

    async createInventory() {
      try {
        const inventory = await fetchPostInventory(
          this.inventory,
          this.productId
        );
        if (inventory) {
          this.alertText = 'Inventario creado con exito';
          // console.log('Respuesta Inventario', inventory);
        } else {
          this.warnings.push('No se pudo crear inventario');
        }
      } catch (error) {
        console.error('Error al intentar crear inventario');
      }
    },
    async updateInventory() {
      try {
        // console.log(this.inventory, this.amountToAdd);
        const updatedInventory = await fetchPutInventory(
          this.inventory,
          this.inventory.id,
          this.amountToAdd
        );
        if (updatedInventory) {
          this.alertText = 'Inventario actualizado con exito';
        } else {
          this.warnings.push('No se pudo actualizar inventario');
        }
      } catch (error) {
        console.error('Error al intentar crear inventario');
      }
    },
    async createInventoryMovement() {
      try {
        const movementType = await this.getOrCreateMovementType(
          BASE_INVENTORY_MOVEMENTS_PRODUCTS.MOVEMENT_TYPE,
          this.userData.id
        );
        const movementReason = await this.getOrCreateMovementReason(
          BASE_INVENTORY_MOVEMENTS_PRODUCTS.MOVEMENT_REASON,
          this.userData.id
        );

        const initialAmount = this.hasInventory
          ? this.inventory.cantidad_disponible
          : 0;
        const amountOfMovement = this.hasInventory
          ? this.amountToAdd
          : this.inventory.cantidad_disponible;
        const finalAmount = initialAmount + amountOfMovement;
        const unitPrice = this.distributionPrice;
        const valueOfMovement = unitPrice * amountOfMovement;

        const movementObj = {
          id_producto: this.productId,
          id_tipo_movimiento: movementType.id,
          id_motivo_movimiento: movementReason.id,
          id_almacen: this.inventory.almacen,
          inventario_inicial: initialAmount,
          cantidad_movimiento: amountOfMovement,
          inventario_final: finalAmount,
          precio_unitario: unitPrice,
          valor_movimiento: valueOfMovement,
          id_moneda: this.product.moneda,
          id_autor: this.userData.id,
          id_institucion_educativa: this.institutionId,
        };

        const responseData = await postItem(
          '/app-inventarios/helpers/crear-movimiento-inventario',
          movementObj
        );

        if (!responseData.e) {
          this.alertText = 'Movimiento de inventario creado con exito';
        } else {
          this.errors.push(
            'No se pudo crear movimiento inventario: ' +
            responseData.e.join(',')
          );
        }
      } catch (error) {
        console.error(
          'Error al intentar crear movimiento de inventario',
          error
        );
        this.errors.push('Error al intentar crear movimiento de inventario');
      }
    },
    async createAndAssignPriceToProduct() {
      try {
        if (!this.distributionPrice) return;
        const priceObj = {
          precio_distribuidor: parseFloat(this.distributionPrice).toFixed(2),
          moneda: this.product.moneda,
          institucion_educativa: this.institutionId,
          autor: this.userData.id,
          distribuidor: this.institutionId,
        };

        const priceResponse = await fetcPostPriceDistributor(priceObj);

        if (!priceResponse) {
          this.warnings.push('No se pudo crear el precio');
          return;
        }

        const priceId = priceResponse.id;
        const assignamentResponse = await fetchAssignPriceDistributor(
          priceId,
          this.productId
        );

        if (!assignamentResponse) {
          this.warnings.push('No se pudo asignar el precio al producto');
          return;
        }

        this.alertText = 'Precio asignado exitosamente';
      } catch (error) {
        console.error(
          'Error al intentar crear o asignar precio a producto',
          error
        );
      }
    },
    async updatePrice() {
      try {
        const priceObj = {
          id: this.distributionPriceObj.id,
          precio_distribuidor: parseFloat(this.distributionPrice).toFixed(2),
          distribuidor: this.institutionId,
          id_moneda: this.product.id_moneda,
        };
        const priceResponse = await putItem(
          `/app-productos/precio-distribuidor/${this.distributionPriceObj.id}`,
          priceObj
        );
        if (priceResponse) {
          this.alertText = 'Precio actualizado exitosamente';
        } else {
          this.warnings.push('No se pudo actualizar el precio del producto');
        }
      } catch (error) {
        console.error('Error al intentar actualizar precio.', error);
      }
    },

    async createProduct() {
      try {
        this.alertText = 'Creando producto...';
        const { documents, images } = await this.processFileUpload(
          this.imagesObj,
          this.docsObj,
          this.userData,
          this.institutionId,
          this.imagesSelected,
          this.docsSelected
        );
        const productResponse = await fetchPostProduct({
          product: this.product,
          taxes: this.taxesSelected,
          options: this.optionsSelected,
          images,
          documents,
        });
        if (!productResponse || !productResponse.id)
          throw new Error(
            'La respuesta del servidor es inválida o falta el ID del producto.'
          );

        const { id: productId } = productResponse;
        this.inventory.producto = productId;
        this.productId = productId;

        if (this.checkInventory) {

          await Promise.all([
            this.createAndAssignPriceToProduct(),
            this.createInventory(),
            this.createInventoryMovement(),
          ]);
        }

        this.alertText = 'Producto creado exitosamente.';
      } catch (error) {
        this.handleErrorCreate(error);
      }
    },

    async handleErrorCreate(error) {
      this.errors.push(`Error al intentar crear producto: ${error.message}`);
      console.error('Error al crear producto:', error);
      this.alertText = 'Ocurrió un error al intentar crear el producto.';
    },

    async updateProduct() {
      try {
        this.alertText = 'Actualizando producto...';
        const { documents, images } = await this.processFileUpload(
          this.imagesObj,
          this.docsObj,
          this.userData,
          this.institutionId,
          this.imagesSelected,
          this.docsSelected
        );
        const updatedProduct = await fetchPutProduct({
          documents,
          images,
          product: this.product,
          taxes: this.taxesSelected,
          options: this.optionsSelected,
        });
        if (!updatedProduct) return;
        await Promise.all([
          this.handlePriceUpdate(),
          this.updateInventoryIfNeeded(),
        ]);

        this.alertText = 'Producto actualizado exitosamente.';
      } catch (error) {
        this.handleErrorUpdate(error);
      }
    },

    async handleErrorUpdate(error) {
      this.errors.push(
        `Error al intentar actualizar producto: ${error.message}`
      );
      console.error('Error al intentar actualizar producto:', error);
      this.alertText = 'Ocurrió un error al intentar actualizar el producto.';
    },

    async handlePriceUpdate() {
      if (!this.distributionPriceObj) {
        if (this.distributionPrice) {
          this.alertText = 'Asignando precio';
          await this.createAndAssignPriceToProduct();
        }
      } else {
        const { precio_distribuidor, moneda } = this.distributionPriceObj;
        if (
          this.distributionPrice !== parseFloat(precio_distribuidor) ||
          this.product.id_moneda !== moneda
        ) {
          this.alertText = 'Actualizando precio de producto';
          await this.updatePrice();
        }
      }
    },

    async updateInventoryIfNeeded() {
      this.inventory.id_producto = this.productId;
      if (this.hasInventory && this.amountToAddValid) {
        await this.updateInventory();
        await this.createInventoryMovement();
      } else {
        if (!this.hasInventory && !this.objectHasNulls(this.inventory)) {
          await this.createInventory();
          await this.createInventoryMovement();
        }
      }
    },

    async createObjects() {
      try {
        this.loading = true;
        this.alertText = 'Cargando...';
        this.showAlert = true;
        await this.createProduct();

        if (this.errors.length) {
          this.alertType = 'error';
          this.alertText = this.errors.join(', ');
        } else {
          this.alertType = this.successAlertType;
          this.alertColor = 'success';
          this.alertText = this.successAlertText;
        }
        this.loading = false;
      } catch (error) {
        console.error(
          'Error al dar de alta Producto y objetos relacionados',
          error
        );
      }
    },
    async updateObjects() {
      try {
        this.loading = true;
        this.alertText = 'Cargando...';
        this.showAlert = true;
        await this.updateProduct();

        this.loading = false;
        if (this.errors.length > 0) {
          this.alertType = 'error';
          this.alertText = this.errors.join(', ');
        } else {
          this.alertType = this.successAlertType;
          this.alertColor = 'success';
          this.alertText = this.successAlertText;
        }
      } catch (error) {
        this.loading = false;
        // console.log(
        //   'Error al actualizar Productos y objetos relacionados',
        //   error
        // );
        this.errors.push(
          'Error al actualizar Productos y objetos relacionados'
        );
        this.alertType = 'error';
        this.alertText = this.errors.join(', ');
      }
    },
    setTabValue(val) {
      this.valueDeterminate = val;
    },
    async save() {
      if (this.isNewMode) {
        if (this.$refs.form.validate()) {
          this.createObjects();
        }
      } else {
        this.updateObjects();
      }
    },
    cancel() {
      this.returnToTable();
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable();
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.clean();
      this.closeAlert();
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
    clean() {
      window.scroll({
        top: 50,
        left: 0,
        behavior: 'smooth',
      });
      this.valueDeterminate = 33;
      this.productPersonalInfo = {
        segundo_nombre: '',
        numero_identidad: '',
      };
      this.productAddress = {
        numero_interior: '',
      };
      this.productTaxInfo = {};
      this.productId = null;
    },
    async fillData() {
      // Populate product properties
      this.productId = this.entity.id;
      // console.log(this.entity);
      this.product = { ...this.entity };
      this.product.calificacion = parseFloat(this.entity.calificacion);
      this.product.marca = this.entity.marca.id;
      this.product.codigo_sat = this.entity.codigo_sat.id;
      this.product.unidad_medida = this.entity.unidad_medida.id;
      this.product.categoria = this.entity.categoria_producto.id;
      this.product.subcategoria = this.entity.subcategoria_producto.id;
      this.product.backorder = this.entity.backorder
        ? this.entity.backorder.id
        : null;
      this.product.institucion_educativa = this.entity.institucion_educativa.id;
      this.product.autor = this.userData.id;

      // Find price for the current distributor
      const priceProduct = this.product.precios.find(
        (price) => price.distribuidor === this.institutionId
      );
      this.distributionPriceObj = priceProduct;
      this.distributionPrice = priceProduct
        ? parseFloat(priceProduct.precio_distribuidor)
        : null;
      this.product.id_moneda = priceProduct ? priceProduct.moneda : null;
      this.product.moneda = priceProduct ? priceProduct.moneda : null;

      // Populate selected taxes, options, images, and docs
      this.taxesSelected = this.product.impuesto.map((tax) => tax.id);
      this.bkTaxesSelected = [...this.taxesSelected];
      this.optionsSelected = this.product.opcion_producto.map(
        (option) => option.id
      );
      this.bkOptionsSelected = [...this.optionsSelected];
      this.imagesSelected = this.product.imagenes.map((image) => image.id);
      this.bkImagesSelected = [...this.imagesSelected];
      this.docsSelected = this.product.documentos.map((doc) => doc.id);
      // this.docs = this.product.documentos
      // this.images = this.product.imagenes
      this.bkDocsSelected = [...this.docsSelected];
      // console.log(this.imagesSelected);
      // console.log(this.docsSelected);
      // console.log(this.product);
      const inventoryRes = await fetchInventoryByProduct(
        this.productId,
        this.institutionId,
        this.userData.id
      );
      const inventory = inventoryRes.results[0];
      if (inventory) {
        this.inventory = { ...this.product.inventario };
        this.inventory.id_producto = this.productId;
        this.inventory.opcion_producto = inventory.opcion_producto.id;
        this.inventory.unidad_medida = inventory.unidad_medida.id;
        this.inventory.id = inventory.id;
        this.inventory.producto = this.productId;
        this.inventory.tipo_opcion = inventory.opcion_producto.id;
        this.inventory.almacen = inventory.almacen.id;
        this.inventory.cantidad_disponible = inventory.cantidad_disponible;
        this.inventory.comentarios = inventory.comentarios;
        this.inventory.unidad_medida = inventory.unidad_medida.id;
        this.inventory.institucion_educativa =
          inventory.institucion_educativa.id;
      } else {
        this.inventory.institucion_educativa = this.institutionId;
        this.inventory.autor = this.userData.id;
      }
    },
    /** Metodos para guardar imágenes */
    saveImagesSelected(imagesObj) {
      this.imagesObj = imagesObj;
      // console.log('Imagenes seleccionadas', this.imagesObj);
    },
    clearImages() {
      this.imagesObj = null;
    },
    /** Metodos para guardar documentos */
    saveDocsSelected(docsObj) {
      this.docsObj = docsObj;
      // console.log('Documentos seleccionados', this.docsObj);
    },
    clearDocs() {
      this.docsObj = null;
    },
    activeItems(array) {
      return array.filter((item) => item.estatus_sistema === true);
    },
  },
};

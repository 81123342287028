import { postItem } from "../../../api/api-methods";
import { createFormDataFromObject } from "./dataUtils";

export const fetchPostDocument = async (documentData, typeDocument, user, institution) => {
  const URL = '/app-personas/documento'
  try {
    const docName = documentData.name;
    const formData = createFormDataFromObject({
      nombre_documento: docName,
      documento: documentData[0],
      tipo_documento: typeDocument,
      institucion_educativa: institution,
      autor: user
    });

    const docResponse = await postItem(URL, formData);

    if (!docResponse || !docResponse.id) {
      throw new Error('Document response is invalid');
    }

    return docResponse;
  } catch (error) {
    console.error('Error al intentar crear el documento:', error);
    throw error;
  }
};
